@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animations */
@-webkit-keyframes bg-scrolling {
  100% {
    background-position: 50px 0px;
  }
}
@-moz-keyframes bg-scrolling {
  100% {
    background-position: 50px 0px;
  }
}
@-o-keyframes bg-scrolling {
  100% {
    background-position: 50px 0px;
  }
}
@keyframes bg-scrolling {
  100% {
    background-position: 50px 0px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 0px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 0px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 0px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 0px;
  }
}
/* Main styles */


.maindiv::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navBar{
  position: sticky;
  z-index:10;
}

.navBar-bgDark{
  background-color: #111111;
}

.navBar-bgLight{
  background-color: #f2f2f2;
}

.active{
  border-bottom : 3px solid #584a89;
}

.navDropDown{
  border-bottom:none;
}

#tsparticles{
  position : fixed;
  width:100%;
  z-index:-1;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 43px;
  height: 20px;
  display: flex;
  border: 1px solid black;
  background-color: #262626;
  border-radius:50px;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  position: relative;
  transform: scale(1.3);
  margin-top:3px;
}

.ball {
  /* width: 14px;
  height: 14px; */
  background-color: white;
  /* position: absolute; */
  /* top: 2px; */
  /* border-radius: 50%; */
  transition: transform 0.2s linear;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #a5abba;
}

.ball::before {
  content: '';
  position: relative;
  width: 100%;
  height: 100%;
  left: 50%;
  float: left;
  background-color: #a5abba;
  transition: border-radius .5s ease, width .5s ease, height .5s ease, left .5s ease, transform .5s ease;
}


/*  target the elemenent after the label*/
.checkbox:checked + .label .ball{
  transform: translateX(22px);
}

.checkbox:checked + .label .ball::before{
  border-radius: 50%;
  width: 150%;
  height: 85%;
  left: 40%;
  transform: translate(-10%, -40%), rotate(-35deg);
}

.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}

.dropdown-content {
  display: none;
  flex-direction: row;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  padding-top:8px;
  padding-bottom:8px;
  padding-left: 16px;
  padding-right:16px;
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.dropdown:hover .dropdown-content {
  display:flex;
  flex-direction: row;
  border-radius: 10px;
}

.dropdown {
  overflow: hidden;
}

.dropdown .dropbtn {  
  border: none;
  outline: none;
}

@import url(https://fonts.googleapis.com/css?family=Raleway);
h2 {
  vertical-align: center;
  text-align: center;
}

html, body {
  margin: 0;
  height: 100%;
}

* {
  font-family: "Raleway";
  box-sizing: border-box;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00BAF0;

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #FFF;
  height: 50px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}



.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #808080;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

/* @media (max-width: 1024px) {

  .bg-mobile-nav{
    background-color:rgb(17, 17, 17) !important;
  }

  .menu-button-container {
    display: flex;
  }
  
  .menu {
    position: absolute;
    top: 0;
    margin-top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color:rgb(17, 17, 17);
    height:250px;
  }
  .menu > li:last-child {
    border-bottom: 1px solid #444;
  }
} */

.NogginLogo{
  background : url("../public/img/NogginLogo.PNG")
}

@font-face {
  font-family: Neutro;
  src: url('../public/fonts/Neutro-ExtraBold.otf');
}

@font-face {
  font-family:DM Sans;
  src: url('../public/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family:DM Sans Bold;
  src: url('../public/fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family:DM Sans Bold;
  src: url('../public/fonts/DMSans-Bold.ttf');
}
@font-face {
  font-family: SF Pro;
  src: url('../public/fonts/SF\ Pro\ Regular.ttf');
}

@font-face {
  font-family: SF Pro Bold;
  src: url('../public/fonts/SF\ Pro\ Bold.ttf');
}

.pageHeading{
  font-style: normal;
  font-weight: bold;
  font-family: Neutro, sans-serif;
}

.screenAnimation{
  margin: -40px 0 0 -160px;
}

.contentHeader{
  background: -webkit-linear-gradient(#FF0064, #4E3F7E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.underlined {
  position: relative;
  /* margin-right: 1rem; */
}
.underlined:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 7px;
  width: 100%;
  border: solid 8px #ff136f;
  border-color: #ff136f transparent transparent transparent;
  border-radius: 30%;
}


.business-bg{
  background-image:none;
}

.business-bg:hover{
  background-image: url("../public/img/businessBg.png");
  background-repeat: no-repeat;
  background-size:cover;
}

.profile-bg{
  background-image:none;
}

.profile-bg:hover{
  /* background-image: url("../public/img/devBg.png"); */
  background-image: url("../public/img/businessBg.png");
  background-repeat: no-repeat;
  background-size: 105% 105%;
}

.profile-bg2{
  background-image:none;
}

.profile-bg2:hover{
  background-image: url("../public/img/businessBg.png");
  /* background-image: url("../public/img/businessBg.png"); */
  background-repeat: no-repeat;
  background-size:110% 110%;
}

.profile-bg3{
  background-image:none;
}

.profile-bg3:hover{
  background-image: url("../public/img/businessBg.png");
  background-repeat: no-repeat;
  background-size:105% 105%;
}

.dev-bg{
  background-image:none;
}

.dev-bg:hover{
  background-image: url("../public/img/devBg.png");
  background-repeat: no-repeat;
  background-size:cover;
}

.users-bg{
  background-image:none;
}

.users-bg:hover{
  background-image: url("../public/img/usersBg.png");
  background-repeat: no-repeat;
  background-size:cover;
}

.serviceDiv{
  background-image: url("../public/img/gradient\ service\ bg.png");
  background-repeat: no-repeat;
  /* background-size:contain; */
  background-size: 100% 100%;
  border-radius: 25px !important;
}

.serviceDivRev{
  background-image: url("../public/img/gradient\ service\ bg\ reverse.png");
  background-repeat: no-repeat;
  /* background-size:contain; */
  background-size: 100% 100%;
}

.serviceDivMobile{
  background-image: url("../public/img/gradient\ service\ bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.serviceDivMobileRev{
  background-image: url("../public/img/gradient\ servicebg\(Mobile\)reverse.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;;
}

@media only screen and (max-width:1535px) {
  .servicesDiv{
    margin-top: 50px !important; 
    margin-bottom: 0 !important;
    margin-left:15px !important;
    margin-right:15px !important;
  }
  .serviceDiv{
    padding-bottom:50px !important;
    padding-top:20px!important;
    background-image: none;
    background: rgb(78,63,126);
    background: linear-gradient(164deg, rgba(78,63,126,1) 35%, rgba(255,0,100,1) 100%);
  }
}

.backgroundDark{
  background-image: url("../public/img/darkbg.PNG");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-size: 100% 100%;
}

.backgroundLight{
  background-image: url("../public/img/lightbg.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-size: 100% 100%;
}


@media only screen and (min-width:374px){
  .portraits{
    margin-top: -20px;
    
  }

  .peopleDiv{
    min-height: 200px;
  }
  .portraitsPlaceholder{
    margin-top: -20px!important;
    margin-left:-16px!important;
    min-height:220px!important;
    min-width:180px!important;
  }
  .portraitsTianChong{
    margin-top:-30px!important;
    max-width: 180px!important;
    margin-left:-15px!important;
  }
}

@media only screen and (min-width:768px){

  .portraits{
    margin-top: -20px;
  }
  .portraitsTheo{
    margin-top: -30px!important; 
  }
  .peopleDiv{
    min-height: 170px;
  }
  .portraitsPlaceholder{
    margin-top: -10px!important;
    margin-left:-15px!important;
    min-height:180px!important;
    min-width:160px!important;
    padding-right: 10px!important;
  }
  .portraitsTianChong{
    margin-top:-30px!important;
    max-width: 180px!important;
    margin-left:-0px!important;
  }
  .underlined:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 7px;
    width: 100%;
    border: solid 10px #ff136f;
    border-color: #ff136f transparent transparent transparent;
    border-radius: 30%;
  }
  
  
  
}

@media only screen and (min-width:1536px) {
  .peopleDiv{
    min-height: 140px;
  }
  .height791{
    min-height:700px!important;
  }
  .portraitsPlaceholder{
    margin-top: -20px!important;
    margin-left:-15px!important;
    min-height:140px!important;
    min-width:140px!important;
    padding-right: 0px!important;
  }
  .portraitsHanInn{
    margin-top:-26px;
  }
  .portraits{
    margin-top:-50px;
  }
  .gradientBg{
    height:450px!important;
  }
}

@media only screen and (max-width:1128px) {
  .portraitsHanInn{
    margin-top: 10px!important;
    max-width: 155px!important;
  }
}

@media only screen and (max-width:1536px) {
  .portraitsHanInn{
    margin-top: 10px;
    max-width: 170px;
  }
}

@media only screen and (max-width:1240px) {
  .portraitsTianChong{
    margin-top: -10px!important;
    max-width: 150px!important;
  }
}

.accordion-button:not(.collapsed)::after{
  background-image: url(../public/img/accordionArrow.png);
}

@media only screen and (min-width:1280px){
  .height791{
    min-height:791px;
  }
}

.bg {
  animation:slide 13s ease-in-out infinite alternate;
  background-image: linear-gradient(30deg, #ebebeb 50%, #dbdbdb 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
  height:100%;
}

.bgDark {
  animation:slide 13s ease-in-out infinite alternate;
  background-image: linear-gradient(30deg, #3b3b3b 50%, #0a0a0a 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
  height:100%;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:14s;
}

.bg3 {
  animation-duration:15s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

.serviceHeader{
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #dcdcdc;
}

.quoteDiv{
  background-image: url(../public/img/QuoteBackground.png);
  overflow:"cover";
  background-repeat: none;
  width:100%;
  height:100%
}

.quoteBg{
  background-image: url(../public/img/quoteBg.png);
  overflow:"cover";
  background-repeat: none;
  width:100%;
  height:100%
}

.gradientBg{
  background: linear-gradient(45deg, rgba(78,63,126,1) 50%, rgba(181,26,111,1) 100%);
  background-repeat: no-repeat;
  background-size:contain;
}

.gradientBgA{
  background: rgb(78,63,126);
  background: linear-gradient(45deg, rgba(78,63,126,1) 50%, rgba(181,26,111,1) 100%);
  background-size:cover;
  background-color: none;
}

.about-us-card{
  padding-bottom:25px;
}

.about-us-card2{
  padding-bottom:25px;
}

@media only screen and (max-width: 426px){
  .about-us-card{
    padding-bottom:150px !important;
  }
  
}

.gradientBgDef{
  background: linear-gradient(70deg, rgba(78,63,126,1) 50%, rgba(181,26,111,1) 100%);
  background-repeat: no-repeat;
  background-size:contain;
}

.gradientBgDef2{
  background: linear-gradient(70deg, rgba(78,63,126,1) 50%, rgba(181,26,111,1) 100%);
  background-repeat: no-repeat;
  background-size:contain;
}


.bg-howNogginWorks{
  background: transparent linear-gradient(226deg, #584A89 0%, #090613 50%);
  opacity: 1;
}

.businessBg-light{
  background-image: url("./img/businessBg-light.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
}


.businessBg-dark{
  background-image: url("./img/businessBg-dark.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
}

.developersBg-light{
  background-image: url("./img/developer light bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
}

.developersBg-dark{
  background-image: url("./img/developer bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
}

@media only screen and (max-width:768px){
  .disable-tablet{
    pointer-events: none; 
    user-select: none;
  }
}

.collapse {
  visibility: inherit !important;}